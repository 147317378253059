<!--
  ეს დივი გვჭირდება, რომ დავრაპოს ჰედერი და როუტერ ჰოლდერი
  და ფუტერი ჩააგდოს ყოველთვის დაბლა.
 -->
<div>
  <app-error-modal *ngIf="showErrorModal | async"></app-error-modal>
  <div
    class="_x_min-h-[270px] sm:_x_min-h-[224px] md:_x_min-h-[144px]"
    [ngClass]="{
      '!_x_min-h-[222px] md:_x_min-h-[124px]': !(selectRibbonActiveState$ | async),
      'md:_x_min-h-[34]': !(userStatus$ | async) && isBasket,
      '!_x_min-h-[347px] sm:!_x_min-h-[300px]':
        (smartBannerService.openSmartBanner$ | async) && !smartBannerService.isFromSafari
    }">
    <app-header></app-header>
  </div>
  <app-toaster></app-toaster>
  <app-global-modal *ngIf="globalModalService.component$ | async"></app-global-modal>

  <div id="router-holder" class="_x_mt-8" [ngClass]="{ 'md:!_x_mt-0': isDifferentSpacePage }">
    <router-outlet></router-outlet>
  </div>

  <app-address-listing *ngIf="isAddressListingModalOpen"></app-address-listing>
  <app-address-form *ngIf="isAddressFormModalOpen"></app-address-form>
  <app-debt-payment-modal *ngIf="orderDebtService.isPaymentModalOpen"></app-debt-payment-modal>
  <app-confirmation-dialog *ngIf="showConfirmationDialog"></app-confirmation-dialog>
  <app-sidebar *ngIf="(layoutStatus$ | async).burgerStatus"></app-sidebar>
  <app-popup-overlay></app-popup-overlay>
  <app-backdrop></app-backdrop>

  <div class="fb-customerchat" attribution="setup_tool" page_id="211265162810563" greeting_dialog_display="icon"></div>
</div>
<app-footer></app-footer>

<div
  *ngIf="isChatOpen"
  class="_x_fixed _x_inset-0 _x_z-[50] _x_h-full _x_w-full _x_bg-white"
  [ngClass]="{ _x_hidden: !isMobileService.isChatFullScreen.value }"></div>
