import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { CONFIG, Config } from '../config.model';
import { Guid } from 'guid-typescript';

@Injectable()
export class ApiService {
  private environment: any;

  constructor(
    private http: HttpClient,
    @Inject(CONFIG) config: Config
  ) {
    this.environment = config.env;
  }

  private getGuidId(): string {
    return Guid.create().toString();
  }

  private formatErrors(error: any) {
    return throwError(error.error || error || ''); // todooooooooooooooo: aq albat unda iyos error.error || error
  }

  get<T = any>(path: string, params?: any, api: string = 'apiUrl', pResponseType?: any): Observable<any> {
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(key => {
        if (Array.isArray(params[key])) {
          params[key].forEach(item => {
            httpParams = httpParams.append(key, item);
          });
        } else if (params[key]) {
          httpParams = httpParams.append(key, params[key]);
        }
      });
      if (!httpParams.has('requestId')) {
        httpParams = httpParams.append('requestId', this.getGuidId());
      }
    }

    return this.http
      .get<T>(`${this.environment[api]}${path}`, { params: httpParams, responseType: pResponseType })
      .pipe(catchError(this.formatErrors));
  }

  put<T = any>(path: string, body: Object = {}, params?: any, api: string = 'apiUrl'): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(key => {
        if (params[key]) {
          httpParams = httpParams.append(key, params[key]);
        }
      });
    }
    return this.http
      .put<T>(`${this.environment[api]}${path}`, JSON.stringify(body), {
        ...options,
        params: httpParams
      })
      .pipe(catchError(this.formatErrors));
  }

  post<T = any>(
    path: string,
    body: any,
    params?: any,
    api: string = 'apiUrl',
    pResponseType?: any,
    customHeaders?: { [key: string]: string }
  ): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (customHeaders) {
      Object.keys(customHeaders).forEach(key => {
        headers = headers.set(key, customHeaders[key]);
      });
    }

    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(key => {
        if (params[key]) {
          httpParams = httpParams.append(key, params[key]);
        }
      });
    }
    return this.http
      .post<T>(`${this.environment[api]}${path}`, JSON.stringify(body), {
        headers,
        params: httpParams,
        responseType: pResponseType
      })
      .pipe(catchError(this.formatErrors));
  }

  upload<T = any>(path: string, body: any, params?: any, api: string = 'apiUrl', pResponseType?: any): Observable<any> {
    const options = {
      // headers: new HttpHeaders({
      //   'Content-Type': 'application/json'
      // })
    };
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(key => {
        if (params[key]) {
          httpParams = httpParams.append(key, params[key]);
        }
      });
    }

    return this.http
      .post(`${this.environment[api]}${path}`, body, {
        ...options,
        params: httpParams,
        responseType: pResponseType
      })
      .pipe(catchError(this.formatErrors));
  }

  delete<T = any>(path, params?: any, api: string = 'apiUrl'): Observable<any> {
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(key => {
        if (params[key]) {
          httpParams = httpParams.append(key, params[key]);
        }
      });
    }

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: params,
      params: httpParams
    };

    return this.http.delete<any>(`${this.environment[api]}${path}`, options).pipe(catchError(this.formatErrors));
  }

  patch<T = any>(path: string, body: Object = {}, params?: any, api: string = 'apiUrl'): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(key => {
        if (params[key]) {
          httpParams = httpParams.append(key, params[key]);
        }
      });
    }
    return this.http
      .patch<T>(`${this.environment[api]}${path}`, JSON.stringify(body), {
        ...options,
        params: httpParams
      })
      .pipe(catchError(this.formatErrors));
  }
}
