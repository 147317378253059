import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, NgModule, OnDestroy, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CookiePopupService } from './cookie-popup.service';
import { Unsubscriber } from 'lib-core';
import { InputSwitchComponent } from '../../shared/components/input-switch/input-switch.component';

@Component({
  selector: 'app-pookie',
  templateUrl: './cookie-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiePopupComponent extends Unsubscriber {
  status: boolean = false;
  isShowed: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private cookieBannerService: CookiePopupService
  ) {
    super();
  }

  closePopup(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.cookieBannerService.hideModal();
      if (localStorage.getItem('access_token') && !localStorage.getItem('close-cookie-popup')) {
        localStorage.setItem('close-cookie-popup', 'true');
      }
      if (!localStorage.getItem('access_token') && !localStorage.getItem('unauthorized-cookie-popup')) {
        localStorage.setItem('unauthorized-cookie-popup', new Date().toString());
      }
    }
  }

  controlShowed(): void {
    this.isShowed = !this.isShowed;
  }
}

@NgModule({
  imports: [RouterModule, CommonModule, InputSwitchComponent],
  declarations: [CookiePopupComponent],
  exports: [CookiePopupComponent]
})
export class CookieBannerModule {}
