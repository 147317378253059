import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Guid } from 'guid-typescript';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class MixpanelHttpService {
  constructor(private api: ApiService) {}

  dyPurchaseEvent(data: DynamicYieldOrdersInterface): Observable<any> {
    return this.api.post(`api/DynamicYield/order`, data, { requestId: this.getGuidId() }, 'mixpanelApiURL');
  }

  getRecommendations(payload: any, apiKey: string): Observable<any> {
    return this.api.post('v2/serve/user/choose', payload, {}, 'dynamicYieldURL', undefined, { 'DY-API-KEY': apiKey });
  }

  private getGuidId(): string {
    return Guid.create().toString();
  }
}

export interface DynamicYieldOrdersInterface {
  orderId: string;
  dyId: string;
  dyIdServer: string;
  activeConsentAccepted: boolean;
  dySession: string;
}
