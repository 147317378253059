<button (click)="toggle()" [disabled]="disabled">
  <div
    [ngClass]="[
      checked ? '_x_justify-end _x_bg-green' : '_x_bg-comparisonPageTogglerGray',
      checked && bgPurple ? '_x_justify-end _x_bg-purple' : '_x_bg-comparisonPageTogglerGray',
      disabled ? '_x_justify-end _x_bg-dark-500' : ''
    ]"
    class="_x_flex _x_h-10 _x_w-[36px] _x_items-center _x_rounded-100 _x_px-1">
    <div [ngClass]="{ '_x_bg-dark-700': disabled }" class="_x_h-8 _x_w-8 _x_rounded-100 _x_bg-white"></div>
  </div>
</button>
