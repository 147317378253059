import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, RendererFactory2 } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserTypeService } from './user-type.service';
import { LegalEntityService } from 'lib-core';
import { Store } from '@ngrx/store';
import { getUserData } from '../store/reducers/user.reducer';
import * as fromUser from '../store/models/user.model';
import { IsMobileService } from './is-mobile.service';

declare var $zoho: any;

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private chatOpenState: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isChatLoaded: boolean = false;
  private get isUserLegalEntity(): boolean {
    return this.userTypeService.isUserLegalEntity;
  }

  private userData$!: Observable<fromUser.UserModel>;

  private userData: fromUser.UserModel;
  private renderer: any;
  constructor(
    private _oauthService: OAuthService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private userTypeService: UserTypeService,
    private legalEntityService: LegalEntityService,
    private store: Store<fromUser.UserModel>,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private isMobileService: IsMobileService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  //chatToggle initializes zohoSalesIq on first click
  public toggleChat(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.isChatLoaded) {
        this.loadZohoSalesIQ();
      }
      if (this.chatOpenState.value) {
        this.closeChat();
      } else {
        this.openChat();
      }
    }
  }

  //chat Opening initializes zohoSalesIq on first click
  public openChat(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.isChatLoaded) {
        this.loadZohoSalesIQ();
      }
      if (this.isChatLoaded) {
        $zoho.salesiq.floatwindow.visible('show');
      }
    }
  }

  public closeChat(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.isChatLoaded) {
        $zoho.salesiq.floatwindow.visible('hide');
      }
    }
  }

  public setUserData(userData?: fromUser.UserModel) {
    if (userData) {
      this.userData = userData;
      return;
    }
    this.userData$ = this.isUserLegalEntity
      ? this.userTypeService.getlegalEntityUser()
      : this.store.select(getUserData);

    this.userData$?.subscribe((res: fromUser.UserModel) => {
      if (JSON.stringify(this.userData) !== JSON.stringify(res)) {
        this.userData = res;
      }
    });
  }
  public showChatLogo(): void {
    if (isPlatformBrowser(this.platformId)) {
      $zoho?.salesiq?.floatbutton.visible('show');
    }
  }

  public hideChatLogo(): void {
    if (isPlatformBrowser(this.platformId)) {
      $zoho?.salesiq?.floatbutton.visible('hide');
    }
  }

  public isChatOpen$(): Observable<boolean> {
    return this.chatOpenState;
  }

  public logOut(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.isChatLoaded) {
        $zoho.salesiq.reset();
      }
    }
  }
  public resetChat(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.isChatLoaded) {
        $zoho.salesiq.reset();
      }
    }
  }

  public registerAuthorizedUser(userData?: fromUser.UserModel): void {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.isChatLoaded) {
        return;
      }
      if (userData) {
        this.userData = userData;
      }
      if (this.isUserLegalEntity) {
        this.userData?.juridicalTitle && $zoho.salesiq.visitor.name(this.userData.juridicalTitle);
      } else {
        this.userData?.firstName && $zoho.salesiq.visitor.name(this.userData.firstName);
      }
      this.userData?.userExternalId && $zoho.salesiq.visitor.id(this.userData.userExternalId);
      this.userData?.email && $zoho.salesiq.visitor.email(this.userData.email);
      this.userData?.phoneNumber && $zoho.salesiq.visitor.info({ phone: this.userData.phoneNumber });
    }
  }

  public loadZohoSalesIQ(): void {
    if (this.isChatLoaded) {
      return;
    }
    if (isPlatformBrowser(this.platformId)) {
      this.setUserData();
      (window as any).$zoho = (window as any).$zoho || {};
      (window as any).$zoho.salesiq = (window as any).$zoho.salesiq || { ready: function () {} };
      const script = document.createElement('script');
      script.id = 'zsiqscript';
      script.src =
        'https://salesiq.zohopublic.eu/widget?wc=siqa401284f06d79564a2fca3bc1da1ae4160a9597e5485f4af363fdce3c985ea25';
      script.defer = true;
      document.body.appendChild(script);
      this.initializeChat();
    }
  }

  private updateChatPositionOnLoad(): void {
    let attempts = 0;
    const maxAttempts = 30;
    const interval = setInterval(() => {
      const chatElement = this.document.querySelector('.zsiq-float.zsiq-flexM');
      if (chatElement) {
        this.positionIcon(chatElement);
        clearInterval(interval);
      }
      attempts++;
      if (attempts >= maxAttempts) {
        clearInterval(interval);
      }
    }, 500);
  }

  private updateChatPosition(): void {
    const chatElement = this.document.querySelector('.zsiq-float.zsiq-flexM');
    if (chatElement) {
      this.positionIcon(chatElement);
    }
  }

  private positionIcon(chatElement) {
    if (this.isMobileService.isUpTo1023px.value) {
      const addClass =
        !this.isMobileService.isChatFullScreen.value && this.chatOpenState.value ? 'chat-bottom-10' : 'chat-bottom-70';
      const removeClass =
        !this.isMobileService.isChatFullScreen.value && this.chatOpenState.value ? 'chat-bottom-70' : 'chat-bottom-10';
      this.renderer.addClass(chatElement, addClass);
      this.renderer.removeClass(chatElement, removeClass);
    } else {
      this.renderer.removeClass(chatElement, 'chat-bottom-70');
      this.renderer.removeClass(chatElement, 'chat-bottom-10');
      this.renderer.addClass(chatElement, 'opacity-1');
    }
  }

  private initializeChat(): void {
    const $this = this;
    $zoho.salesiq.ready = () => {
      this.isChatLoaded = true;
      this.listenToChatOpenStateChanges($this);
      this.listenToScreenResolutionChange();
      this.setChatLanguage();
      this.chatLogo();
      this.updateChatPositionOnLoad();
      if (this._oauthService.hasValidAccessToken()) {
        this.registerAuthorizedUser();
      } else {
        this.resetChat();
      }
    };
  }

  private listenToChatOpenStateChanges($this: any) {
    $zoho.salesiq.chat.close(function () {
      $this.chatOpenState.next(false);
      $this.updateChatPosition();
    });
    $zoho.salesiq.chat.open(function () {
      $this.chatOpenState.next(true);
      $this.updateChatPosition();
    });
  }
  private listenToScreenResolutionChange() {
    this.isMobileService.isUpTo1023px.subscribe(res => {
      this.updateChatPosition();
    });
    this.isMobileService.isChatFullScreen.subscribe(res => {
      this.updateChatPosition();
    });
  }

  private chatLogo(): void {
    $zoho.salesiq.chat.logo(
      'https://cdn.staging.extra.ge/images/products/original/2a2e2298-6d90-4c1c-a839-e6ab64a1e00a.jpg'
    );
  }

  private setChatLanguage(): void {
    $zoho.salesiq.floatwindow.onlinetitle('ჩატი');
    $zoho.salesiq.floatwindow.offlinetitle('ჩატი');
    $zoho.salesiq.language('ka');
  }
}
