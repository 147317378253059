<div
  class="_x_fixed _x_bottom-16 _x_right-50prc _x_z-[51] _x_w-calc-w-full-32 _x_max-w-[434px] _x_translate-x-[50%] _x_rounded-12 _x_bg-white _x_py-12 md:_x_bottom-40 md:_x_right-40 md:_x_translate-x-unset">
  <!--  ქუქიების სტანდარტ პოპაპი -->
  <div *ngIf="!isShowed" class="_x_flex _x_flex-col _x_px-16">
    <img class="_x_w-24" src="/assets/img/svg/cookie.svg" alt="image of cookie" />
    <h4 class="_x_mb-4 _x_mt-8 _x_font-bold _x_text-4 _x_text-dark">Cookie ფაილებზე წვდომა</h4>
    <p class="_x_text-left _x_font-medium _x_text-3 _x_text-dark">
      Cookie ფაილებს ვიყენებთ ვებგვერდის სწორად ფუნქციონირებისა და შენი გამოცდილების გაუმჯობესებისთვის
    </p>
    <div class="_x_mt-8 _x_flex _x_w-full _x_items-center _x_justify-between _x_gap-8">
      <button
        aria-label="Justify"
        (click)="closePopup()"
        class="_x_flex _x_h-21 _x_w-[131px] _x_items-center _x_justify-center _x_rounded-8 _x_bg-purple _x_p-2 _x_font-bold _x_text-3 _x_text-white md:_x_w-[177px]">
        დადასტურება
      </button>
      <button
        (click)="controlShowed()"
        aria-label="Justify"
        class="_x_flex _x_h-21 _x_w-[131px] _x_items-center _x_justify-center _x_rounded-8 _x_bg-purple-100 _x_p-2 _x_font-bold _x_text-3 _x_text-purple md:_x_w-[177px]">
        მართვა
      </button>
    </div>
  </div>

  <!--  ქუქიების მართვის პოპაპი -->
  <div *ngIf="isShowed">
    <div class="_x_px-16">
      <div class="_x_mb-24 _x_flex _x_items-center _x_gap-4">
        <i (click)="controlShowed()" class="_x_icon-arrow-left _x_cursor-pointer _x_text-8"></i>
        <h4 class="_x_font-bold _x_text-6 _x_text-dark">Cookie ფაილები</h4>
      </div>

      <div class="_x_pb-12">
        <div class="_x_mb-8 _x_flex _x_items-center _x_justify-between _x_gap-8">
          <h5 class="_x_font-bold _x_text-4 _x_text-dark">აუცილებელი</h5>
          <app-input-switch disabled="true"></app-input-switch>
        </div>
        <p class="_x_font-medium _x_text-3 _x_text-dark">
          საჭიროა ვებგვერდის გამართული ფუნქციონირებისთვის, თანხმობის გარეშე არ გექნებათ წვდომა ვებგვერდზე განთავსებულ
          ინფორმაციასთან
        </p>
      </div>

      <div class="_x_border-t _x_border-gray _x_py-12">
        <div class="_x_mb-8 _x_flex _x_items-center _x_justify-between _x_gap-8">
          <h5 class="_x_font-bold _x_text-4 _x_text-dark">მარკეტინგული</h5>
          <app-input-switch checked="true" bgPurple="true"></app-input-switch>
        </div>
        <p class="_x_font-medium _x_text-3 _x_text-dark">
          გამოიყენება შენს ინტერესებზე მორგებული პროდუქტების და სერვისების შესათავაზებლად
        </p>
      </div>
    </div>

    <div class="_x_flex _x_justify-center _x_border-t _x_border-gray _x_px-16">
      <button
        aria-label="Justify"
        (click)="closePopup()"
        class="_x_mt-12 _x_flex _x_h-21 _x_w-full _x_items-center _x_justify-center _x_rounded-8 _x_bg-purple _x_p-2 _x_font-bold _x_text-3 _x_text-white">
        დადასტურება
      </button>
    </div>
  </div>
</div>
